<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import { menuItems } from "./menu";
import {authComputed, InqueryNewCountComputed} from "@/state/helpers";

/**
 * Sidenav component
 */
const compare = (a1, a2) => a1.filter(v => a2.includes(v)).length;

export default {
  data() {
    return {
      menuData: null,
    };
  },
  mounted: function () {

    if (document.getElementById("side-menu")) new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
      if(!matchingMenuItem) {
        // 없으면 한번더 수행
        const strIndex2 = item.lastIndexOf("/");
        const item2 = item.substr(0, strIndex2).toString();
        matchingMenuItem = links[paths.indexOf(item2)];
      }
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },

  },
  computed: {
    ...authComputed,
    ...InqueryNewCountComputed,

    hasNewCount() {
      return (item) => {
        return this.menuInqueryNewCount(item) > 0
      }
    },
    menuInqueryNewCount() {
      return (item) => {
        if ( item.code === 'app-error' ) return this.newInqueryAppErrorCount
        else if ( item.code === 'inquiry' ) return this.newInqueryTotalCount
        else if ( item.code === 'apps' ) return this.newInqueryAppsCount
        else if ( item.code === 'partners' ) return this.newInqueryPartnerCount
        else if ( item.code === 'users' ) return this.newInqueryHomePageCount
        else if ( item.code === 'webpartners' ) return this.newInqueryWebPartnerCount
        else if ( item.code === 'evidences' ) return this.newInqueryEvidenceCount
        return 0
      }
    },
    newInqueryAppErrorCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_errors : 0
    },
    newInqueryTotalCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_total - this.inqueryNewCount.inquery_errors : 0
    },
    newInqueryAppsCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_app : 0
    },
    newInqueryPartnerCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_partner : 0
    },
    newInqueryHomePageCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_user : 0
    },
    newInqueryWebPartnerCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_webpartner : 0
    },
    newInqueryEvidenceCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_evidence : 0
    },

    filterRoleMenuItems() {
      // 권한 체크를 한다.
      let isSuperAdmin = (this.currentUser.roles && this.currentUser.roles.indexOf('admin:super') > -1)
      // console.log(isSuperAdmin)
      if(isSuperAdmin) return menuItems
      else {
        let filterMenuItems = menuItems.filter( item => {
          if(item.subItems) {
            let filterSubItems = item.subItems.filter( subItem => {
              return subItem.role && this.currentUser.roles && compare(subItem.role, this.currentUser.roles) > 0
            })
            item.subItems = filterSubItems
          }
          return item.role && this.currentUser.roles && compare(item.role, this.currentUser.roles) > 0
        })
        return filterMenuItems
      }
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in filterRoleMenuItems" :key="item.id">
        <li class="menu-title" v-if="item.isTitle">
          {{ $t(item.label) }}
        </li>
        <li v-if="!item.isTitle && !item.isLayout">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
                :class="`badge rounded-pill bg-danger float-end`"
                v-if="hasNewCount(item)">{{menuInqueryNewCount(item)}}</span>
          </a>

          <router-link
            :to="item.link"
            v-if="!hasItems(item)"
            class="side-nav-link-ref"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-danger float-end`"
              v-if="hasNewCount(item)">{{menuInqueryNewCount(item)}}</span>
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="subitem.link"
                v-if="!hasItems(subitem)"
                class="side-nav-link-ref">{{ $t(subitem.label) }}
                <span
                    :class="`badge rounded-pill bg-danger float-end`"
                    v-if="hasNewCount(subitem)">{{menuInqueryNewCount(subitem)}}</span>
              </router-link>
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                >{{ $t(subitem.label) }}</a
              >
              <ul
                v-if="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li
                  v-for="(subSubitem, index) of subitem.subItems"
                  :key="index"
                >
                  <router-link
                    :to="subSubitem.link"
                    class="side-nav-link-ref"
                    >{{ $t(subSubitem.label) }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
<script>
import { SimpleBar } from "simplebar-vue3"

import SideNav from "./side-nav";
import { layoutComputed } from "@/state/helpers";

/**
 * Sidebar component
 */
export default {
  components: { SimpleBar, SideNav },
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
        onScroll: this.scrollSimpleBar
      },
    };
  },
  methods: {
    scrollSimpleBar(e) {
      sessionStorage.setItem('simplebar.scrollTop', e.target.scrollTop);
    },
    onRoutechange() {
      // console.log('onRoutechange');

      // 기존 소스는 대메뉴가 상단에 스크롤 되는 방식
      // 수정 : 현재 스크롤위치를 그대로 보존방식으로 변경
      let _this = this
      this.$nextTick(function () {
        if(_this.$refs.isSimplebar) {
          let scrollTop = sessionStorage.getItem('simplebar.scrollTop') * 1;
          _this.$refs.isSimplebar.value.getScrollElement().scrollTop = scrollTop;
          // console.log(scrollTop)
        }
      })

      /*
     setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if(this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
     */
    },
  },
  mounted() {
    // leftSidebarType: 'light'
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-sidebar-size");
    document.body.classList.remove("vertical-collpsed");

    // layoutWidth: 'fluid'
    document.body.setAttribute("data-layout-mode", "fluid");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout-scrollable");
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <div class="vertical-menu">
    <SimpleBar
      :settings="settings"
      @scroll="scrollSimpleBar"
      class="h-100"
      ref="isSimplebar"
    >
      <SideNav />
    </SimpleBar>
  </div>
</template>
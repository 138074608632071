<script>
// import {getHostUrl} from "@/helpers/api-config";
// import axios from "axios";
import { SimpleBar } from "simplebar-vue3";

import useVuelidate from "@vuelidate/core";
import {authComputed, InqueryNewCountComputed} from "@/state/helpers";
import store from "@/state/store";
import {helpers, required, sameAs} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {menuItems} from "@/components/menu";

/**
 * Nav-bar Component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      lang: "en",
      text: null,
      flag: null,
      value: null,
      isOpenModalChangePassword: false,
      changeMyPasswordForm: {
        password : '',
        new_password : '',
        new_password_confirmation : '',
      },
      changeMyPasswordFormSubmitted: false,
    };
  },
  validations: function() {
    let _this = this
    return {
      changeMyPasswordForm: {
        password: {
          required: helpers.withMessage("비밀번호를 입력해 주세요.", required),
        },
        new_password: {
          required: helpers.withMessage("새 비밀번호를 입력해 주세요.", required)
        },
        new_password_confirmation: {
          required: helpers.withMessage("새 비밀번호를 한번더 입력해 주세요.", required),
          sameAsPassword: helpers.withMessage("새 비밀번호와 비밀번호가 일치 하지 않습니다.", sameAs(_this.changeMyPasswordForm.new_password))
        },
      }
    }
  },
  components: { SimpleBar },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.isOpenModalChangePassword = false
      }
    },
    closeModal() {
      this.isOpenModalChangePassword = false
      window.history.back()
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    logoutUser() {
      store.dispatch("apiAuth/logout");
    },
    resetChangeMyPassword() {
      this.changeMyPasswordForm.password = ''
      this.changeMyPasswordForm.new_password = ''
      this.changeMyPasswordForm.new_password_confirmation = ''
      store.dispatch('notification/clear')
      this.v$.$reset();
    },
    changeMyPassword() {
      store.dispatch('notification/clear')

      this.changeMyPasswordFormSubmitted = true
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.changeMyPasswordForm.$invalid) {
        this.changeMyPasswordFormSubmitted = false
      } else {
        let params = {
          password: this.changeMyPasswordForm.password,
          new_password: this.changeMyPasswordForm.new_password,
          new_password_confirmation: this.changeMyPasswordForm.new_password_confirmation,
        }
        store.dispatch("apiUsers/changeMyPassword", params).then((result) => {
          this.changeMyPasswordFormSubmitted = false

          if(result.success) {
            Swal.fire(result.message, "", "success");
            this.isOpenModalChangePassword = false
          } else {
            Swal.fire(result.message, "", "error");
          }
        }, () => {
          this.changeMyPasswordFormSubmitted = false
        });
      }
    },
  },
  computed: {
    ...authComputed,
    ...InqueryNewCountComputed,
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isNewInquery() {
      return this.newInqueryTotalCount > 0
    },
    newInqueryTotalCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_total : 0
    },
    newInqueryAppErrorCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_errors : 0
    },
    newInqueryAppsCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_app : 0
    },
    newInqueryPartnerCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_partner : 0
    },
    newInqueryHomePageCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_user : 0
    },
    newInqueryWebPartnerCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_webpartner : 0
    },
    newInqueryEvidenceCount() {
      return this.inqueryNewCount ? this.inqueryNewCount.inquery_evidence : 0
    },
    setTitle() {
      let menuId = this.$store ? store.state.layout.menuId : 100100;
      let parentId = Math.floor(menuId / 100) * 100

      let menu = null

      if (parentId == menuId) {
        menu = menuItems.find(v => v.id == menuId)
      } else {
        let parent = menuItems.find(v => v.id == parentId)
        if (parent.subItems) {
          menu = parent.subItems.find(v => v.id == menuId)
        }
      }

      return (menuId == 100100) ? '관리자' : (menu ? this.$t(menu.label) : '관리자')
    }
  },
  watch: {
    isOpenModalChangePassword(newVal) {
      if(newVal === false) {
        this.resetChangeMyPassword()
      }
    },
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-only.png" alt height="25" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="32" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-only.png" alt height="25" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="32" />
            </span>
          </router-link>
        </div>
        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm pe-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars font-size-20 mt-2"></i>
        </button>
        <div class="py-4">
          <h2 class="pt-1 font-size-15 text-white" style="letter-spacing:0.05rem">{{ setTitle }}</h2>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell" :class="{'bx-tada' : isNewInquery}"></i>
            <span class="badge bg-danger rounded-pill">{{newInqueryTotalCount}}</span>
          </template>

          <div class="p-3 pb-2 mb-3 border-1 border-bottom">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 bg">
                  알림
                </h6>
              </div>
              <div class="col-auto"></div>
            </div>
          </div>
          <SimpleBar class="pb-2" style="max-height: 230px;">
            <a v-if="newInqueryTotalCount === 0" href="javascript: void(0);" class="text-reset notification-item">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 align-middle mb-1"></i>
                알림 내역이 없습니다.
              </div>
            </a>

            <router-link to="/app/error?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryAppErrorCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                앱문제신고 <strong class="text-danger">{{ newInqueryAppErrorCount }}</strong>건이 있습니다.
              </div>
            </router-link>

            <router-link to="/inquiry/app/apps?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryAppsCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                앱문의 <strong class="text-danger">{{ newInqueryAppsCount }}</strong>건이 있습니다.
              </div>
            </router-link>

            <router-link to="/inquiry/app/partners?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryPartnerCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                제휴문의 <strong class="text-danger">{{ newInqueryPartnerCount }}</strong>건이 있습니다.
              </div>
            </router-link>

            <router-link to="/inquiry/home/users?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryHomePageCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                홈페이지문의 <strong class="text-danger">{{ newInqueryHomePageCount }}</strong>건이 있습니다.
              </div>
            </router-link>

            <router-link to="/inquiry/home/webpartners?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryWebPartnerCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                제휴문의(PC) <strong class="text-danger">{{ newInqueryWebPartnerCount }}</strong>건이 있습니다.
              </div>
            </router-link>

            <router-link to="/inquiry/evidences?status=Request&dateField=created_at&startDate=all&endDate=all&period=0" class="text-reset notification-item"
              v-if="newInqueryEvidenceCount > 0">
              <div class="mb-2 mx-3">
                <i class="bx bx-error-circle font-size-20 text-danger align-middle mb-1"></i>
                증거자료요청 <strong class="text-danger">{{ newInqueryEvidenceCount }}</strong>건이 있습니다.
              </div>
            </router-link>

          </SimpleBar>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <span class="d-inline-block align-middle"><i class="bx bx-user font-size-17"></i></span>
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="currentUser">
                {{ currentUser.name }}
              </div>
              <div v-else>-</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block mx-1"></i>
          </template>
          <!-- item-->
          <b-dropdown-item href="javascript: void(0);" @click="isOpenModalChangePassword = true">
            <i class="bx bx-user-circle font-size-16 align-middle me-1"></i>
            비밀번호변경
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="javascript: void(0);" @click="logoutUser">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            로그아웃
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-modal
        v-model="isOpenModalChangePassword"
        id="modal-center"
        @show="openModal"
        @hidden="closeModal"
        centered
        size="sm"
        title="비밀번호 변경"
        title-class="font-18"
        hide-footer
    >

      <div
          v-if="notification.message"
          :class="'alert ' + notification.type"
      >
        {{ notification.message }}
      </div>

      <b-form class="row gy-2 gx-3 align-items-center" @submit.prevent="changeMyPassword">
        <b-form-group
            class="mb-3"
            id="password-group"
            label="기존 비밀번호"
            label-for="password"

            :invalid-feedback="v$.changeMyPasswordForm.password.$error ? v$.changeMyPasswordForm.password.$errors[0].$message : null"
            :state="!v$.changeMyPasswordForm.password.$error"
        >
        <b-form-input id="password"
            v-model="changeMyPasswordForm.password"
            type="password"
            placeholder="기존 비밀번호를 입력하세요."

            :state="!v$.changeMyPasswordForm.password.$error"
            :disabled="changeMyPasswordFormSubmitted"
        ></b-form-input>
        </b-form-group>

        <b-form-group
            class="mb-3"
            id="password-group"
            label="새 비밀번호"
            label-for="new_password"

            :invalid-feedback="v$.changeMyPasswordForm.new_password.$error ? v$.changeMyPasswordForm.new_password.$errors[0].$message : null"
            :state="!v$.changeMyPasswordForm.new_password.$error"
        >
          <b-form-input id="new_password"
            v-model="changeMyPasswordForm.new_password"
            type="password"
            placeholder="새 비밀번호를 입력하세요."

            :state="!v$.changeMyPasswordForm.new_password.$error"
            :disabled="changeMyPasswordFormSubmitted"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            class="mb-3"
            id="password-group"
            label="새 비밀번호 확인"
            label-for="new_password_confirmation"

            :invalid-feedback="v$.changeMyPasswordForm.new_password_confirmation.$error ? v$.changeMyPasswordForm.new_password_confirmation.$errors[0].$message : null"
            :state="!v$.changeMyPasswordForm.new_password_confirmation.$error"
        >
          <b-form-input id="new_password_confirmation"
              v-model="changeMyPasswordForm.new_password_confirmation"
              type="password"
              placeholder="새 비밀번호를 한번더 입력하세요."

              :state="!v$.changeMyPasswordForm.new_password_confirmation.$error"
              :disabled="changeMyPasswordFormSubmitted"
          ></b-form-input>
        </b-form-group>
        <div class="mt-4 d-grid">
          <b-button type="submit" variant="primary" class="btn-block" :disabled="changeMyPasswordFormSubmitted">비밀번호 변경</b-button>
        </div>
      </b-form>
    </b-modal>
  </header>
</template>

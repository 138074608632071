<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">Copyright © 도와줘. All rights reserved.</div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
